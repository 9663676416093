import p1 from "../src/interior/001.png"
import p2 from "../src/interior/002.png"
import p3 from "../src/interior/003.png"

// dummy data for portfolio
const interior = [
    {
        id: 1,
        image: p1
    }, {
        id: 2,
        image: p2
    }, {
        id: 3,
        image: p3
    },
]

export default interior